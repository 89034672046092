import React from 'react'
import Navbar from '../components/Navbar';

import Footer from '../components/Footer';
import Img2 from '../components/Img2';
import { ContactUs } from '../components/ContactUs';

const Contact = () => {
  return (
    <div>
      <Navbar />
      <Img2 heading="CONTACT." text="LET'S HAVE A CHAT" />
      
     <ContactUs/>
      <Footer />
    </div>
  )
}

export default Contact