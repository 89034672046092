import pro1 from "../assets/sherry-store.jpg"
import pro2 from "../assets/Easy.jpg"
const ProjectCardData=[
    {
        imgsrc: pro1,
        title:"Ecommerce Website",
        text:" E-commercial website for clothes and treading product applications, Build the project using HTML, CSS and JavaScript",
        view: "https://github.com/vaibhav04096/sherry-store"
    },
    {
        imgsrc: pro2,
        title:"TicTakToe Game",
        text:" Completed and deployed a fully functional Tic Tac Toe game,demonstrating the ability to turn conceptual ideas into working software. using (HASKELL)",
        view: "https://github.com/vaibhav04096/HASKELL"
        
    },
];
export default ProjectCardData;